import React from "react"
import Fade from "react-reveal/Fade"
import Plx from "react-plx"
import Text from "../texts/Text"
import FigureBox, { boxFormats } from "../../components/FigureBox"
import { Link } from "gatsby"
// redux
import { invertHeader, resetHeader } from "../../actions"
import { connect } from "react-redux"
// import axios from "axios"
import _ from "lodash"
import { isBrowser } from "../../utils/browser"

import { isMobile } from "../../utils/breakpoints"
import Accordion from "../../components/Accordion"
import axios from "axios"
import InterviewList from "./InterviewList"

class JobList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
      tabActiveState: null,
      ghData: [],
    }
    this.slider = null
    this.department = null
  }

  headerClassName() {
    if (isMobile(this.props.currentBreakpoint, `tablet`)) {
      return boxFormats.square
    } else if (isMobile(this.props.currentBreakpoint, `tabletLandscape`)) {
      return boxFormats.postalCard
    } else {
      return boxFormats.half
    }
  }

  selectOffice = i => {
    let _this = this
    return function() {
      _this.setState({
        activeTab: i,
        tabActiveState: `is-active`,
      })
    }
  }

  componentDidMount() {
    this.props.invertHeader()

    const Flickity = isBrowser() ? require(`flickity`) : undefined

    // init slider
    this.slider = new Flickity(this.refs.slider, {
      cellAlign: `left`,
      draggable: true,
      wrapAround: true,
      percentPosition: false,
      contain: false,
      prevNextButtons: false,
      pageDots: false,
      selectedAttraction: 0.085,
      friction: 0.65,
      autoPlay: true,
    })

    // get data
    axios
      .get(`https://boards-api.greenhouse.io/v1/boards/wk/departments`)
      .then(response => {
        // console.log()
        const departments = response.data.departments
        this.setState({ ghData: departments })
      })
      .catch(error => {
        console.log(error)
      })
  }
  componentWillUnmount() {
    this.props.resetHeader()
  }

  jobGHJobsAPI(office_short_name) {
    let officeName = []
    this.state.ghData.map(department => {
      // console.log(department)
      department.jobs.map(office => {
        if (
          office.location.name === office_short_name
          // &&
          // office.internal_job_id
        ) {
          officeName.push({
            greenhouseId: office.id,
            job_id: office.internal_job_id,
            title: office.title,
            office: office.location.name,
            department: department.name,
          })
        }
      })
    })
    let sortedItems = _.sortBy(officeName, function(sort) {
      return sort.department === `General Application` ? 1 : 0
    })
    let result = sortedItems.reduce((acc, d) => {
      const found = acc.find(a => a.department === d.department)
      const value = { greenhouseId: d.greenhouseId, title: d.title }
      if (found) {
        found.name_val.push(value)
      } else {
        acc.push({ department: d.department, name_val: [value] })
      }
      return acc
    }, [])
    // console.log(result)
    this.department = result.map((offices, index) => (
      <Accordion
        key={index}
        title={offices.department}
        content={offices.name_val.map(jobTitle => (
          <Link
            key={jobTitle.greenhouseId}
            to={`/jobs/apply/${jobTitle.greenhouseId}/?gh_jid=${
              jobTitle.greenhouseId
            }`}
          >
            {jobTitle.title}
          </Link>
        ))}
      />
    ))
    return this.department
  }

  jobsSpotlightSlides = () => {
    // recreate array
    this.slides = []
    // create a slider for each slide

    this.props.data.careerHeaderImage.images.map((spotlightImage, index) =>
      this.slides.push(
        <FigureBox
          key={index}
          source={spotlightImage}
          format={this.headerClassName()}
        />
      )
    )

    return this.slides
  }

  render() {
    // console.log(this.state.ghData)
    return (
      <section className="career__list">
        <Plx
          parallaxData={[
            {
              start: 0,
              duration: 1250,
              properties: [
                {
                  startValue: 0,
                  endValue: isMobile(this.props.currentBreakpoint) ? -50 : -125,
                  property: `translateY`,
                },
              ],
            },
          ]}
        >
          <Fade speed={450} delay={125}>
            <header className="career__header_image mb-4 mb-xl-5 jobs__Slider">
              {/* <Text
                text={this.props.data.careerHeaderImage}
                figureFormat={this.headerClassName()}
                overflowContainer={false}
              /> */}
              <div ref="slider">{this.jobsSpotlightSlides()}</div>
            </header>
          </Fade>
        </Plx>

        <header className="career__header_intro container-fluid mb-5">
          {/* intro text */}
          <hgroup className="row mb-4">
            <h1 className="col-12 col-md-5">Jobs</h1>
            <aside className="col-12 col-md-7 no-padding__right">
              <Text
                text={this.props.data.careerIntroText}
                breakTextAnimation={true}
                textWrapperTag={`h3`}
                container={false}
                contentClassName="col-12 no-padding"
              />
            </aside>
          </hgroup>
        </header>

        {/* <div className="container">
          <Router>
            <SomeSubPage path="/jobs/:id" />
          </Router>
        </div> */}

        {/* Career content starts from here */}
        {/* office list */}
        <div className="offices__list">
          <div className="container">
            <hgroup className="col-12 d-md-block career_office_list">
              <nav className="nav offices__list__nav">
                <a
                  className={`nav-item nav-item0-active` + this.state.activeTab}
                  href="javascript:;"
                  onClick={this.selectOffice(0)}
                  aria-hidden="true"
                  role="button"
                >
                  Portland
                </a>
                <a
                  className={`nav-item nav-item1-active` + this.state.activeTab}
                  href="javascript:;"
                  onClick={this.selectOffice(1)}
                  aria-hidden="true"
                  role="button"
                >
                  Amsterdam
                </a>
                <a
                  className={`nav-item nav-item2-active` + this.state.activeTab}
                  href="javascript:;"
                  onClick={this.selectOffice(2)}
                  aria-hidden="true"
                  role="button"
                >
                  New York
                </a>
                <a
                  className={`nav-item nav-item3-active` + this.state.activeTab}
                  href="javascript:;"
                  onClick={this.selectOffice(3)}
                  aria-hidden="true"
                  role="button"
                >
                  Tokyo
                </a>
                <a
                  className={`nav-item nav-item4-active` + this.state.activeTab}
                  href="javascript:;"
                  onClick={this.selectOffice(4)}
                  aria-hidden="true"
                  role="button"
                >
                  London
                </a>
                <a
                  className={`nav-item nav-item5-active` + this.state.activeTab}
                  href="javascript:;"
                  onClick={this.selectOffice(5)}
                  aria-hidden="true"
                  role="button"
                >
                  Shanghai
                </a>
                <a
                  className={`nav-item nav-item6-active` + this.state.activeTab}
                  href="javascript:;"
                  onClick={this.selectOffice(6)}
                  aria-hidden="true"
                  role="button"
                >
                  Delhi
                </a>
                {/* <a
                  className={`nav-item nav-item7-active` + this.state.activeTab}
                  href="javascript:;"
                  onClick={this.selectOffice(7)}
                  aria-hidden="true"
                  role="button"
                >
                  Mumbai
                </a> */}
                <a
                  className={`nav-item nav-item7-active` + this.state.activeTab}
                  href="javascript:;"
                  onClick={this.selectOffice(7)}
                  aria-hidden="true"
                  role="button"
                >
                  São Paulo
                </a>
                {/* <a
                  className={`nav-item nav-item9-active` + this.state.activeTab}
                  href="javascript:;"
                  onClick={this.selectOffice(9)}
                  aria-hidden="true"
                  role="button"
                >
                  Mexico
                </a> */}
              </nav>
            </hgroup>
          </div>
        </div>
        <div>
          <div>
            <div className="container">
              <div
                className={
                  `col-12 d-md-block accordion careers officeTab` +
                  this.state.activeTab
                }
              >
                <div className="offices office0">
                  {this.jobGHJobsAPI(`Portland, Oregon, United States`)}
                </div>
                <div className="offices office1">
                  {this.jobGHJobsAPI(`Amsterdam, Noord-Holland, Netherlands`)}
                </div>
                <div className="offices office2">
                  {this.jobGHJobsAPI(`New York, New York, United States`)}
                </div>
                <div className="offices office3">
                  {this.jobGHJobsAPI(`東京都, Tokyo Prefecture, Japan`)}
                </div>
                <div className="offices office4">
                  {this.jobGHJobsAPI(`London, England, United Kingdom`)}
                </div>
                <div className="offices office5">
                  {this.jobGHJobsAPI(`Shanghai, China`)}
                </div>
                <div className="offices office6">
                  {this.jobGHJobsAPI(`Delhi, Delhi, India`)}
                </div>
                {/* <div className="offices office7">
                  {this.jobGHJobsAPI(`Mumbai, India`)}
                </div> */}
                <div className="offices office7">
                  {this.jobGHJobsAPI(`São Paulo, São Paulo, Brazil`)}
                </div>
                {/* <div className="offices office9">
                  {this.jobGHJobsAPI(`Mexico`)}
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <InterviewList
            data={this.props.data}
            allNews={this.props.data.allNews}
          />
        </div>
      </section>
    )
  }
}

JobList.propTypes = {}

// export default withBreakpoints(JobList)

export default connect(
  null,
  {
    invertHeader,
    resetHeader,
  }
)(JobList)

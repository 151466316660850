import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import JobList from "./JobList"

import { Router } from "@reach/router"
import JobDetails from "./JobDetails"

class JobIndex extends React.Component {

  render() {
    return (
      <Layout>
        <SEO 
        title={`Jobs`} 
        description={`Jobs.`} 
        seoFields={this.props.data.seoFields}
        />
      <div>
        <JobList data={this.props.data} />
        <Router>
          <JobDetails path="/jobs/apply/:id" />
        </Router>
      </div>
      </Layout>
    )
  }
}

export default JobIndex
export const query = graphql`
  query JobIndex {
    seoFields: contentfulSeoFields(slug: { eq: "career-seo" }) {
      ...seoFieldsFragment
    }

    careerHeaderImage: contentfulTexts(slug: { eq: "career-header-image" }) {
      ...textFragment
    }

    careerIntroText: contentfulTexts(slug: { eq: "career-intro-text" }) {
      ...textFragment
    }
    allNews: allContentfulNews(
      sort: { fields: [publishDate], order: DESC }
      filter: { node_locale: { eq: "en-US" }, isInterview: { eq: true } }
    ) {
      edges {
        node {
          ...newsFragmentBase
        }
      }
    }
  }
`

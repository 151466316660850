import React from "react"

class Accordion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      class: `accordion__item`,
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = () => {
    if (this.state.open) {
      this.setState({ open: false, class: `accordion__item` })
    } else {
      this.setState({ open: true, class: `accordion__item active` })
    }
  }

  render() {
    return (
      <div className={this.state.class + ` ` + this.props.office}>
        <div className="accordion__head" role = "button" tabIndex="-1" onClick={this.handleClick} onKeyDown={this.handleClick}>
          {this.props.title}
        </div>
        <div className="accordion__content">{this.props.content}</div>
      </div>
    )
  }
}

export default Accordion

import React from "react"
import { withBreakpoints } from "react-breakpoints"
import _ from "lodash"

import indexColumns from "../../utils/indexColumns"
import ContentPreview, {
  ContentPreviewLayouts,
} from "../preview/ContentPreview"
import { boxFormats } from "../../components/FigureBox"
import { isMobile } from "../../utils/breakpoints"

class InterviewList extends React.Component {
  constructor(props) {
    super(props)

    // all news excluding featured
    this.NewsIndex = this.getNews(0, this.propsNewsLength())

    // column settings
    this.maxColumn = 100 // maximum number of column before reverting count to 1
    this.maxVisible = 100

    this.indexColumns = new indexColumns(0, this.maxColumn)

    this.state = {
      qaVisibleClassName: `is-hidden`,
      qaButtonClassName: ``,
    }
  }

  propsNews() {
    return this.props.allNews && this.props.allNews.edges !== undefined
      ? this.props.allNews.edges
      : this.props.allNews
  }

  propsNewsLength() {
    return this.propsNews() ? this.propsNews().length : 0
  }

  getNews(slice = 0, length) {
    return _.slice(this.propsNews(), slice, length)
  }

  layout(index) {
    let className = []

    className.push(`col-12 col-md-4`)
    className.push(ContentPreviewLayouts.compact)

    return className.join(` `)
  }

  listNews(startsAt = 0, max = null) {
    let dataSet = max ? this.getNews(startsAt, max) : this.NewsIndex
    // console.log(this.getNews(startsAt, max))
    if (dataSet.length > 0) {
      // XXX: fixing hot reloader issue
      this.indexColumns.reset()

      // will hold content return
      let content = []

      // loop each news
      dataSet.map((news, index) => {
        this.indexColumns.increment()

        content.push(
          <ContentPreview
            key={index}
            index={index}
            contentData={news}
            contentType="jobs"
            layout={this.layout(startsAt + index)}
            visible={true}
            delay={this.indexColumns.delay}
          />
        )
      })

      return content
    }
  }

  headerClassName() {
    if (isMobile(this.props.currentBreakpoint, `tablet`)) {
      return boxFormats.square
    } else if (isMobile(this.props.currentBreakpoint, `tabletLandscape`)) {
      return boxFormats.postalCard
    } else {
      return boxFormats.half
    }
  }

  render() {
    return (
      <section className="working__list mt-4">
        {/* rest of news */}
        <section className="news__list">
          <div className="row mx-0">
            {this.listNews(0, this.propsNewsLength())}
          </div>
        </section>
      </section>
    )
  }
}

InterviewList.propTypes = {}

export default withBreakpoints(InterviewList)
